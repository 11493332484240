import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AccountRequestDTO, AccountRequestHistoryDTO, AuthDetails, FormDTO } from '../../../types';
import { useAuth } from '../../../context/AuthContext';
import Clipboard from 'react-clipboard.js';
import { formatTimestampTime } from '../../../formatTimestamp';
import {mdiClose, mdiContentCopy, mdiLockOutline} from '@mdi/js';
import { Icon } from '@mdi/react';


import './AccountRequestDetails.scss';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
// Assuming you have an extended interface for the detailed data
interface AccountRequestDetailDTO extends AccountRequestDTO {
  // Additional fields
}

const AccountRequestDetails: React.FC = () => {
    const { accessToken } = useAuth();
  const { id } = useParams();
  const [details, setDetails] = useState<AccountRequestDetailDTO | null>(null);
  const [history, setHistory] = useState<AccountRequestHistoryDTO[]>([]);
  const [form, setForm] = useState<FormDTO | null>(null);
  const [auth, setAuth] = useState<AuthDetails | null>(null);
  const [getAuth, setGetAuth] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetails = async () => {
      setDetails(null);
      setHistory([]);
      setAuth(null);
      setGetAuth(false);
      const response = await axios.get(`${process.env.REACT_APP_SERVICE_BASEPATH}/account-requests/${id}`, {headers: {Authorization: `Bearer ${accessToken}`}}); // Adjust API endpoint as needed
      setDetails(response.data);
    };

    if (id) fetchDetails();
  }, [id]);

  useEffect(() => {
    const fetchHistory = async () => {
      const response = await axios.get(`${process.env.REACT_APP_SERVICE_BASEPATH}/account-request-history/${details?.id}`, {headers: {Authorization: `Bearer ${accessToken}`}}); // Adjust API endpoint as needed
      setHistory(response.data);
    };

    if (details?.id) fetchHistory();
  }, [details, auth]);

  useEffect(() => {
    const fetchForm = async () => {
      const response = await axios.get(`${process.env.REACT_APP_SERVICE_BASEPATH}/forms/${details?.externalId}`, {headers: {Authorization: `Bearer ${accessToken}`}}); // Adjust API endpoint as needed
      setForm(response.data);
    };

    if (details?.externalId) fetchForm();
  }, [details, auth]);
  
  useEffect(() => {
    const fetchAuth = async () => {
      const response = await axios.get(`${process.env.REACT_APP_SERVICE_BASEPATH}/account-requests/${id}/auth`, {headers: {Authorization: `Bearer ${accessToken}`}}); // Adjust API endpoint as needed
      setAuth(response.data);
    };

    if (getAuth) fetchAuth();
  }, [getAuth]);

  if (!details) return <div className='account-request-loading'>
    <LoadingIndicator />
  </div>;


  
  

  return (
    <div className='account-request-details fade-in'>
      <div className='details-header'>
        <h2>REQ #{details.id}</h2>
        <span className='date-created'>Created {formatTimestampTime(details.dateCreated.toString())}</span><br />
        <span className={'tag-' + details.status }>{details.status}</span>
        <button className='close-button' onClick={() => navigate('/')}><Icon path={mdiClose} size={1} /></button>
      </div>
      <div className='details-body'>
        <div>
          <h3>Auth Details</h3>
          {!auth && details.accessTokenPresent && <div className='detail-section align-center'>
            {!getAuth && <button className='icon-button icon' onClick={() => setGetAuth(true)}>show auth details <Icon path={mdiLockOutline} size={1} /></button>}
            {getAuth && <LoadingIndicator />}
          </div>}
        {auth && 
          <dl>
            <div className="item">
              <dt>Inst ID</dt>
              <dd>{auth.instId}</dd>
            </div>
            <div className="item">
              <dt>Account</dt>
              <dd>{auth.account} 
                <Clipboard className='copy' data-clipboard-text={auth.account}>
                <Icon path={mdiContentCopy} size={0.5} />
                </Clipboard>
              </dd>
            </div>
            <div className="item">
              <dt>Routing</dt>
              <dd>{auth.routing}
              <Clipboard className='copy' data-clipboard-text={auth.routing}>
                <Icon path={mdiContentCopy} size={0.5} />
                </Clipboard>
              </dd>
            </div>
          </dl>
        }
        {!details.accessTokenPresent && 
          <dl>
            <div className="item">
              <dt>Awaiting auth</dt>
              <dd></dd>
            </div>
          </dl>
        }
        </div>

        <h3>Request Info</h3>
      <dl>
      <div className='item'>
        <dt>Request Link</dt>
        <dd><a href={`${process.env.REACT_APP_INVEST_APP_BASEPATH}/request/${details.token}`} target='_blank'>{process.env.REACT_APP_INVEST_APP_BASEPATH}/request/{details.token}</a></dd>
      </div>
      <div className="item">
        <dt>Requested By</dt>
        <dd>{details.requestedBy}</dd>
      </div>
      <div className="item">
        <dt>Type</dt>
        <dd>{details.type}</dd>
      </div>
      <div className="item">
        <dt>To Email</dt>
        <dd>{details.toEmail}</dd>
      </div>
      <div className="item">
        <dt>To Phone</dt>
        <dd>{details.toPhone}</dd>
      </div>
      <div className="item">
        <dt>Token</dt>
        <dd>{details.token}</dd>
      </div>
      <div className="item">
        <dt>Date Created</dt>
        <dd>{formatTimestampTime(details.dateCreated.toString())}</dd>
      </div>
      <div className="item">
        <dt>Status</dt>
        <dd>{details.status}</dd>
      </div>
      <div className="item">
        <dt>Note</dt>
        <dd>{details.note}</dd>
      </div>
      <div className="item">
        <dt>Account Number</dt>
        <dd>{details.accountNumber}</dd>
      </div>
      <div className="item">
        <dt>First Name</dt>
        <dd>{details.firstName}</dd>
      </div>
      <div className="item">
        <dt>Last Name</dt>
        <dd>{details.lastName}</dd>
      </div>
    </dl>
      
    {form &&  <>
    <h3>Request Info</h3>
      <dl>
      {Object.entries(form.formattedValues).map(([key, value]) => (
      <div className='item'>
        <dt>{key}</dt>
        <dd>{value}</dd>
      </div>
      ))}
    </dl>
    </>}

    <h3>History</h3>
    <div className='history detail-section'>
        <ul>
          {history.map((history) => (
            <li>
              <div className='history-list-item'>
              <span className='date'>{formatTimestampTime(history.timestamp.toString())}</span>
              <span className='event'>{history.event}</span>
              <div className='detail'>{history.detail}</div>
              </div>
            </li>
             ))}
          </ul>
    </div>

    </div>
    </div>
  );
};

export default AccountRequestDetails;
